<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 text-center" v-if="step == 1">
      <img
        :src="require('@/assets/images/check.webp')"
        style="width: 200px; max-width: 100%; border-radius: 50%"
        alt=""
      />
      <br />
      <br />
      قبل إنشاء الجدول سوف نعرض عليك نظرة نسريعة على البيانات المُدخلة
      مُسبقاً<br />
      للتأكد من صحة البيانات.
    </div>
    <div class="col-12 col-lg-8 g c" v-if="step == 1">
      <div class="card">
        <div class="card-header">
          <h4>الخطوة #1 التأكد من الإسناد للمعلمين</h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>المعلم</th>
                <th>النصاب</th>
                <th>الحالة</th>
              </thead>
              <tbody>
                <tr
                  v-for="teacher in teachers"
                  :key="teacher.id"
                  @click="$router.push('/settings/teachers#' + teacher.id)"
                >
                  <td>
                    {{ teacher.fullname }}
                  </td>
                  <td>
                    {{ teacher.selectedLessons }} من {{ teacher.lessons }} حصة
                  </td>
                  <td>
                    <span
                      class="badge bg-success btn-block"
                      v-if="
                        teacher.selectedLessons == teacher.lessons &&
                        teacher.lessons != 0
                      "
                    >
                      <i class="fa fa-check"></i>
                      جيد
                    </span>
                    <span
                      class="badge bg-secondary btn-block"
                      v-if="teacher.lessons == 0"
                    >
                      <i class="fa fa-info"></i>
                      لم يُعد
                    </span>
                    <span
                      class="badge bg-primary btn-block"
                      v-if="
                        new Number(teacher.selectedLessons) <
                        new Number(teacher.lessons)
                      "
                    >
                      <i class="fa fa-info"></i>
                      لم يكتمل
                    </span>
                    <span
                      class="badge bg-danger btn-block"
                      v-if="
                        new Number(teacher.selectedLessons) >
                        new Number(teacher.lessons)
                      "
                    >
                      <i class="fa fa-times"></i>
                      زائد
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-success" @click="step = 2">
              التالي
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 g c" v-if="step == 2">
      <div class="card">
        <div class="card-header">
          <h4>الخطوة #2 التأكد من انصبة المواد بالنسبة لعدد الحصص</h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>الصف</th>
                <th>الفصل</th>
                <th>النصاب</th>
                <th>الحالة</th>
              </thead>
              <tbody>
                <template v-for="classroom in classrooms">
                  <tr
                    :key="classroom.id"
                    @click="
                      $router.push(
                        '/settings/classes#' +
                          classroom.classname.id +
                          '#' +
                          classroom.id
                      )
                    "
                  >
                    <td>
                      {{ classroom.classname.title }}
                    </td>
                    <td>
                      {{ classroom.title }}
                    </td>
                    <td>{{ classroom.subjects_ }} من {{ classroom.days_ }}</td>
                    <td>
                      <span
                        class="badge bg-success btn-block"
                        v-if="
                          classroom.subjects_ == classroom.days_ &&
                          classroom.days_ != 0
                        "
                      >
                        <i class="fa fa-check"></i>
                        جيد
                      </span>
                      <span
                        class="badge bg-secondary btn-block"
                        v-if="classroom.days_ == 0"
                      >
                        <i class="fa fa-info"></i>
                        لم يُعد
                      </span>
                      <span
                        class="badge bg-primary btn-block"
                        v-if="classroom.subjects_ < classroom.days_"
                      >
                        <i class="fa fa-info"></i>
                        لم يكتمل
                      </span>
                      <span
                        class="badge bg-danger btn-block"
                        v-if="classroom.subjects_ > classroom.days_"
                      >
                        <i class="fa fa-times"></i>
                        زائد
                      </span>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-secondary" @click="step = 1">
              <i class="fa fa-arrow-right"></i>
              السابق
            </button>
            &nbsp;
            <button class="btn btn-relief-success" @click="step = 3">
              التالي
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 g c" v-if="step == 3">
      <div class="card">
        <div class="card-header">
          <h4>الخطوة #3 التأكد من توزيع المواد</h4>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>المادة</th>
                <th v-for="classroom in classrooms" :key="classroom.id">
                  {{ classroom.title }}
                  <br />
                  <small>
                    {{ classroom.classname.title }}
                  </small>
                </th>
              </thead>
              <tbody>
                <tr v-for="subject in subjects_full" :key="subject.name">
                  <td>
                    {{ subject.name }}
                  </td>
                  <td v-for="classroom in classrooms" :key="classroom.id">
                    <span
                      class="badge bg-danger btn-block"
                      v-if="!subject.classrooms[classroom.id]"
                    >
                      لا يوجد
                    </span>
                    <span v-if="subject.classrooms[classroom.id]">
                      <span class="badge bg-primary btn-block">
                        {{
                          teacherName(
                            subject.classrooms[classroom.id].teachers[0]
                          )
                        }}
                      </span>
                      <span class="badge bg-light-primary btn-block">
                        {{ subject.classrooms[classroom.id].total }} حصة
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-relief-secondary" @click="step = 2">
              <i class="fa fa-arrow-right"></i>
              السابق
            </button>
            &nbsp;
            <button class="btn btn-relief-success" @click="step = 4">
              التالي
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 g c" v-if="step == 4 && !calendar">
      <div class="card">
        <div class="card-body text-center">
          <button class="btn btn-relief-success btn-lg" @click="createTable()">
            إنشاء الجدول الآن
            <i class="fa fa-arrow-left"></i>
          </button>
          <br /><br />
          <button class="btn btn-relief-secondary" @click="step = 3">
            <i class="fa fa-arrow-right"></i>
            السابق
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-5 g c" v-if="calendar">
      <br />
      <div class="card">
        <div class="card-body text-center">
          <div class="form-group text-left">
            <label for="">عنوان الجدول (لسهولة الحفظ)</label>
            <input
              type="text"
              class="form-control form-control-lg"
              v-model="title"
            />
          </div>
          <button
            class="btn btn-relief-primary btn-lg"
            @click="saveTable()"
            v-if="!edit"
          >
            <i class="fa fa-save"></i>
            حفظ الجدول في "الجداول المحفوظة"
          </button>
          <button
            class="btn btn-relief-primary btn-lg"
            @click="saveTable()"
            v-if="edit"
          >
            <i class="fa fa-save"></i>
            حفظ التعديلات
          </button>
          <div v-if="!edit">
            <br /><br />
            <p>
              يتم حفظ الجدول الحالي مع بيانات الفصول والمعلمين والمواد، ولن يتم
              التأثير في الجدول عند التغيير بيانات الفصول او المواد او المعلمين.
              <br />
              لكن بعد تغيير بياناتهم يمكنك انشاء جدول جديد.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 g c" v-if="fullcalendar">
      <div class="card">
        <div class="card-body">
          <div class="col-12 text-center">
            <a @click="fullcalendar = false">
              <u> إغلاق عرض الكل </u>
            </a>
          </div>
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered custom-table">
              <thead>
                <th>اليوم</th>
                <th>الأولى</th>
                <th>الثانية</th>
                <th>الثالثة</th>
                <th>الرابعة</th>
                <th>الخامسة</th>
                <th>السادسة</th>
                <th>السابعة</th>
                <th>الثامنة</th>
              </thead>
              <tbody>
                <tr v-for="day in days" :key="day.code">
                  <td>
                    {{ day.name }}
                  </td>
                  <td v-for="index in 8" :key="index">
                    <span
                      v-for="classroom in classrooms"
                      :key="classroom.id"
                      :style="'color: #' + classroom.color"
                    >
                      {{ classroom.title }}:
                      <span
                        v-if="
                          calendar[day.code + '|' + classroom.id + '|' + index]
                        "
                      >
                        <span
                          v-if="
                            calendar[
                              day.code + '|' + classroom.id + '|' + index
                            ].text
                          "
                          class="badge bg-primary"
                          v-html="
                            calendar[
                              day.code + '|' + classroom.id + '|' + index
                            ].text
                          "
                        >
                        </span>
                        <span
                          v-if="
                            !calendar[
                              day.code + '|' + classroom.id + '|' + index
                            ].text
                          "
                        >
                          {{
                            calendar[
                              day.code + "|" + classroom.id + "|" + index
                            ].teacher_name
                          }}
                          <br />
                          <u>
                            {{
                              subjectName(
                                calendar[
                                  day.code + "|" + classroom.id + "|" + index
                                ].subject_id
                              )
                            }}</u
                          >
                        </span>
                      </span>
                      <span v-if="errors[classroom.id]">
                        <span v-if="errors[classroom.id][day.code]">
                          <br />
                          <span
                            v-if="errors[classroom.id][day.code][index]"
                            class="bg-danger text-white"
                          >
                            {{ errors[classroom.id][day.code][index].text }}
                          </span>
                        </span>
                      </span>
                      <span
                        v-if="
                          !calendar[day.code + '|' + classroom.id + '|' + index]
                        "
                        class="badge bg-secondary"
                      >
                        خارج التوزيع
                      </span>
                      <hr />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12" v-if="calendar">
      <div class="card">
        <div class="card-body">
          <div class="col-12 text-right">
            <small class="text-muted"
              ><u style="cursor: pointer" @click="fullcalendar = true">
                عرض الكل
              </u></small
            >
          </div>
          <div class="col-12 table-responsive">
            <app-collapse>
              <app-collapse-item
                v-for="classroom in classrooms"
                :key="classroom.id"
                :title="
                  (errorsCount[classroom.id]
                    ? ' [هناك ' + errorsCount[classroom.id] + ' تعارض] '
                    : '') +
                  classroom.classname.title +
                  ': ' +
                  classroom.title
                "
              >
                <table class="table table-hover table-bordered custom-table">
                  <thead>
                    <th>اليوم</th>
                    <th>الأولى</th>
                    <th>الثانية</th>
                    <th>الثالثة</th>
                    <th>الرابعة</th>
                    <th>الخامسة</th>
                    <th>السادسة</th>
                    <th>السابعة</th>
                    <th>الثامنة</th>
                  </thead>
                  <tbody>
                    <tr v-for="day in days" :key="day.code">
                      <td>
                        {{ day.name }}
                      </td>
                      <td
                        v-for="index in 8"
                        :key="index"
                        class="drag"
                        :fullid="day.code + '|' + classroom.id + '|' + index"
                        @click="drag(day.code, classroom.id, index)"
                        v-b-tooltip.hover.top="dragText"
                      >
                        <span
                          v-if="
                            calendar[
                              day.code + '|' + classroom.id + '|' + index
                            ]
                          "
                        >
                          <span
                            v-if="
                              calendar[
                                day.code + '|' + classroom.id + '|' + index
                              ].text
                            "
                            class="badge bg-primary"
                            v-html="
                              calendar[
                                day.code + '|' + classroom.id + '|' + index
                              ].text
                            "
                          >
                          </span>
                          <span
                            v-if="
                              !calendar[
                                day.code + '|' + classroom.id + '|' + index
                              ].text
                            "
                          >
                            {{
                              calendar[
                                day.code + "|" + classroom.id + "|" + index
                              ].teacher_name
                            }}
                            <br />
                            <u>
                              {{
                                subjectName(
                                  calendar[
                                    day.code + "|" + classroom.id + "|" + index
                                  ].subject_id
                                )
                              }}</u
                            >
                          </span>
                        </span>
                        <span
                          v-if="
                            !calendar[
                              day.code + '|' + classroom.id + '|' + index
                            ]
                          "
                          class="badge text-muted"
                        >
                          <i>فارغة</i>
                        </span>

                        <span v-if="errors[classroom.id]">
                          <span v-if="errors[classroom.id][day.code]">
                            <br />
                            <span
                              v-if="errors[classroom.id][day.code][index]"
                              class="bg-danger text-white"
                            >
                              {{ errors[classroom.id][day.code][index].text }}
                            </span>
                          </span>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </app-collapse-item>
            </app-collapse>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BOverlay,
  BModal,
  BFormRadioGroup,
  BFormCheckbox,
  BTooltip,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ArrowDownLeftIcon } from "vue-feather-icons";
import { subject } from "@casl/ability";
import index from "vue-prism-component";
export default {
  components: {
    BOverlay,
    BModal,
    BFormRadioGroup,
    BFormCheckbox,
    AppCollapse,
    BTooltip,
    AppCollapseItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      selectedDrag: {},
      viewSolveErrorsButton: true,
      lastErrorsTotal: 9999,
      errors: {},
      dragText: "إضغط للنقل",
      fullcalendar: false,
      teachers: [],
      subjects: [],
      classes: [],
      id: null,
      title: "جدول جديد " + new Date().toISOString().toString().split("T")[0],
      errorsCount: {},
      classrooms: [],
      calendar: null,
      totalBefore: 0,
      step: 1,
      edit: false,
      days: [
        {
          code: "1",
          name: "الأحد",
          list: {},
        },
        {
          code: "2",
          name: "الاثنين",
          list: {},
        },
        {
          code: "3",
          name: "الثلاثاء",
          list: {},
        },
        {
          code: "4",
          name: "الاربعاء",
          list: {},
        },
        {
          code: "5",
          name: "الخميس",
          list: {},
        },
      ],
    };
  },
  created() {
    var g = this;
    if (window.location.href.includes("#")) {
      $.post(api + "/table/table", {
        jwt: user.jwt,
        id: window.location.href.split("#")[1],
      })
        .then(function (r) {
          r = JSON.parse(r);
          g.classes = r.classes.list;
          g.calendar = r.calendar;
          g.subjects = r.subjects.list;
          g.teachers = r.teachers.list;
          g.title = r.title;
          g.id = r.id;
          //
          g.classes.forEach((element) => {
            element.classrooms.forEach(function (a) {
              a.subjects_ = 0;
              a.days_ = 0;
              for (var [key, value] of Object.entries(a.subjects)) {
                if (key != "e" && value) {
                  a.subjects_ = a.subjects_ + new Number(value);
                }
              }
              for (var [key, value] of Object.entries(a.days)) {
                if (key != "e" && value) {
                  a.days_ = a.days_ + new Number(value);
                }
              }
              a.classname = element;
              function getDarkColor() {
                var color = "";
                for (var i = 0; i < 6; i++) {
                  color += Math.floor(Math.random() * 10);
                }
                return color;
              }
              a.color = getDarkColor();
              g.classrooms.push(a);
            });
          });
          var subjects_full = {};
          g.teachers.forEach(function (s) {
            if (s.lessons_list) {
              s.lessons_list.forEach(function (l) {
                if (!subjects_full[l.subject_id]) {
                  subjects_full[l.subject_id] = {
                    classrooms: {},
                  };
                }
                if (!subjects_full[l.subject_id].classrooms[l.classroom_id]) {
                  subjects_full[l.subject_id].classrooms[l.classroom_id] = {
                    teachers: [],
                    total: 0,
                  };
                }
                if (
                  !subjects_full[l.subject_id].classrooms[
                    l.classroom_id
                  ].teachers.includes(s.id)
                ) {
                  subjects_full[l.subject_id].classrooms[
                    l.classroom_id
                  ].teachers.push(s.id);
                }
                subjects_full[l.subject_id].classrooms[l.classroom_id].total =
                  subjects_full[l.subject_id].classrooms[l.classroom_id].total +
                  new Number(l.value);
              });
            }
          });
          g.subjects_full = [];
          for (var [key, value] of Object.entries(subjects_full)) {
            g.subjects_full.push({
              name: g.subjectName(key),
              classrooms: value.classrooms,
            });
          }
          g.step = 4;
          g.edit = true;
          g.checkErrors();
          setTimeout(() => {
            g.solveErrors(true);
            g.loading = false;
          }, 1000);
          //
        })

        .catch(function (e) {
          console.log(4, e);

          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    } else {
      $.post(api + "/table/settings", {
        jwt: user.jwt,
        type: "classes",
      })
        .then(function (r) {
          r = JSON.parse(r);
          g.classes = r.list ? r.list : [];
          g.classes.forEach((element) => {
            element.classrooms.forEach(function (a) {
              a.subjects_ = 0;
              a.days_ = 0;
              for (var [key, value] of Object.entries(a.subjects)) {
                if (key != "e" && value) {
                  a.subjects_ = a.subjects_ + new Number(value);
                }
              }
              for (var [key, value] of Object.entries(a.days)) {
                if (key != "e" && value) {
                  a.days_ = a.days_ + new Number(value);
                }
              }
              a.classname = element;
              function getDarkColor() {
                var color = "";
                for (var i = 0; i < 6; i++) {
                  color += Math.floor(Math.random() * 10);
                }
                return color;
              }
              a.color = getDarkColor();
              g.classrooms.push(a);
            });
          });
          $.post(api + "/table/settings", {
            jwt: user.jwt,
            type: "subjects",
          })
            .then(function (r) {
              r = JSON.parse(r);
              g.subjects = r;
              g.subjects = g.subjects.list ? g.subjects.list : [];
              $.post(api + "/table/settings", {
                jwt: user.jwt,
                type: "teachers",
              })
                .then(function (r) {
                  r = JSON.parse(r);
                  g.teachers = r.exists != "no" ? r.list : [];
                  var subjects_full = {};
                  g.teachers.forEach(function (s) {
                    if (s.lessons_list) {
                      s.lessons_list.forEach(function (l) {
                        if (l) {
                          if (!subjects_full[l.subject_id]) {
                            subjects_full[l.subject_id] = {
                              classrooms: {},
                            };
                          }
                          if (
                            !subjects_full[l.subject_id].classrooms[
                              l.classroom_id
                            ]
                          ) {
                            subjects_full[l.subject_id].classrooms[
                              l.classroom_id
                            ] = {
                              teachers: [],
                              total: 0,
                            };
                          }
                          if (
                            !subjects_full[l.subject_id].classrooms[
                              l.classroom_id
                            ].teachers.includes(s.id)
                          ) {
                            subjects_full[l.subject_id].classrooms[
                              l.classroom_id
                            ].teachers.push(s.id);
                          }
                          subjects_full[l.subject_id].classrooms[
                            l.classroom_id
                          ].total =
                            subjects_full[l.subject_id].classrooms[
                              l.classroom_id
                            ].total + new Number(l.value);
                        }
                      });
                    }
                  });
                  g.subjects_full = [];
                  for (var [key, value] of Object.entries(subjects_full)) {
                    g.subjects_full.push({
                      name: g.subjectName(key),
                      classrooms: value.classrooms,
                    });
                  }
                  g.loading = false;
                })
                .catch(function (e) {
                  console.log(1, e);
                  swal.fire(
                    "حدث خطأ اثناء الاتصال بالخادم",
                    "برجاء المحاولة مرة أخرى",
                    "warning"
                  );
                  g.loading = false;
                });
            })
            .catch(function (e) {
              console.log(2, e);
              swal.fire(
                "حدث خطأ اثناء الاتصال بالخادم",
                "برجاء المحاولة مرة أخرى",
                "warning"
              );
              g.loading = false;
            });
        })

        .catch(function (e) {
          console.log(3, e);
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    }
  },
  methods: {
    subjectName(id) {
      var n = null;
      this.subjects.forEach(function (a) {
        if (a.id == id) {
          n = a.fullname;
        }
      });
      return n;
    },
    teacherName(id) {
      var n = null;
      this.teachers.forEach(function (a) {
        if (a.id == id) {
          n = a.fullname;
        }
      });
      return n;
    },
    subjectTable(id) {
      var n = {};
      this.subjects.forEach(function (a) {
        if (a.id == id) {
          n = a.table;
        }
      });
      return n;
    },
    subjectHasNear(id) {
      var n = false;
      this.subjects.forEach(function (a) {
        if (a.id == id && a.has_near == "true") {
          n = true;
        }
      });
      return n;
    },
    createTable() {
      this.viewSolveErrorsButton = true;
      this.id = Math.random().toString().split(".")[1];
      var g = this,
        bigArr = {};
      this.loading = true;
      setTimeout(() => {
        g.classrooms.forEach(function (c) {
          var calendar = {},
            calendar2 = [],
            arr = [];
          g.teachers.forEach(function (teacher) {
            if (teacher.lessons_list) {
              teacher.lessons_list.forEach(function (l) {
                if (l) {
                  if (l.classroom_id == c.id) {
                    for (var x = 1; x <= l.value; x++) {
                      arr.push({
                        teacher_id: teacher.id,
                        teacher_name: teacher.shortname,
                        teacher_full_name: teacher.fullname,
                        classroom: c.id,
                        classname: c.classname.id,
                        subject_id: l.subject_id,
                        banned: teacher.banned_days,
                        days: teacher.days,
                        kk: Math.random().toString().split(".")[1],
                      });
                    }
                  }
                }
              });
            }
          });
          for (const [key, value] of Object.entries(c.days)) {
            if (key != "e" && new Number(value) > 0) {
              calendar[key] = {};
              for (var i = 1; i <= value; i++) {
                calendar[key][i] = "nothing";
                calendar2.push({
                  day: key,
                  lesson: i,
                  subject: false,
                });
              }
            }
          }
          for (const [key, value] of Object.entries(c.subjects)) {
            if (key != "e" && new Number(value) > 0) {
              var i = 0;
              calendar2.forEach(function (a) {
                var max = g.subjectHasNear(key) ? 2 : 1,
                  count = 0,
                  mm = 0;
                var ok = true;
                calendar2.forEach(function (a2) {
                  if (a2.day == a.day && key == a2.subject && a2.subject) {
                    count++;
                  }
                  if (key == a2.subject && a2.subject) {
                    mm++;
                  }
                });
                g.classrooms.forEach(function (cca) {
                  var cid = cca.id;
                  if (bigArr[cid]) {
                    if (bigArr[cid][a.day] && bigArr[cid][a.day][a.lesson]) {
                      if (bigArr[cid][a.day][a.lesson].subject_id == key) {
                        ok = false;
                      }
                    }
                  }
                });
                if (count < max && mm < new Number(value) && ok) {
                  if (!calendar2[i].subject) {
                    calendar2[i].subject = key;
                  }
                }
                i++;
              });
            }
          }
          for (const [key, value] of Object.entries(c.subjects)) {
            if (key != "e" && new Number(value) > 0) {
              calendar2.forEach(function (a) {
                var mm = 0;
                calendar2.forEach(function (a2) {
                  if (key == a2.subject && a2.subject) {
                    mm++;
                  }
                });
                if (mm < new Number(value)) {
                  calendar2.every(function (as, index) {
                    if (!as.subject) {
                      calendar2[index].subject = key;
                      return false;
                    } else {
                      return true;
                    }
                  });
                }
              });
            }
          }
          calendar2.forEach(function (a) {
            if (calendar[a.day][a.lesson] == "nothing") {
              var d = false;
              arr.forEach(function (t) {
                if (t.subject_id == a.subject) {
                  calendar[a.day][a.lesson] = t;
                  // var index = arr.indexOf(t);
                  d = true;
                  // if (index !== -1) {
                  //   arr.splice(index, 1);
                  // }
                }
              });
              if (!d) {
                if (a.subject) {
                  calendar[a.day][a.lesson] = {
                    text: "لا يوجد معلم مناسب مادة " + g.subjectName(a.subject),
                  };
                }
              }
            }
          });
          calendar2.forEach(function (a) {
            if (calendar[a.day][a.lesson] == "nothing") {
              calendar[a.day][a.lesson] = {
                text: "لا شيئ",
              };
            }
          });
          bigArr[c.id] = calendar;
        });
        var y = {};
        for (const [key, value] of Object.entries(bigArr)) {
          for (const [key1, value1] of Object.entries(value)) {
            for (const [key2, value2] of Object.entries(value1)) {
              /*
              key = classroom
              key1 = day
              key2 = lesson
            */
              var t = value[key1][key2];
              y[key1 + "|" + key + "|" + key2] = t;
            }
          }
        }
        g.calendar = y;
        g.checkErrors();
        setTimeout(() => {
          //  g.solveErrors(true);
          g.loading = false;
        }, 1000);
      }, 100);
    },
    checkErrors() {
      var g = this;
      g.errors = {};
      g.errorsCount = {};
      for (const [key, value] of Object.entries(this.calendar)) {
        var day = key.split("|")[0],
          classroom = key.split("|")[1],
          lesson = key.split("|")[2];
        if (value) {
          var t = value,
            no = false;
          if (t.days) {
            for (const [key3, value3] of Object.entries(t.days)) {
              if (key3 == day && value3 != "true") {
                no = {
                  type: "teacher-day",
                  text: "هذا المعلم لا يُدرس في هذا اليوم",
                };
              }
            }
            for (const [key2, value2] of Object.entries(g.calendar)) {
              var day2 = key2.split("|")[0],
                classroom2 = key2.split("|")[1],
                lesson2 = key2.split("|")[2];
              if (value2) {
                if (value2.teacher_id) {
                  if (
                    day == day2 &&
                    value2.teacher_id == t.teacher_id &&
                    lesson2 == lesson &&
                    classroom2 != classroom
                  ) {
                    no = {
                      type: "teacher-lesson",
                      text:
                        "هذا المدرس لديه حصة في نفس التوقيت في فصل " +
                        `'${g.classroomName(classroom2)}'`,
                    };
                  }
                }
              }
            }
          }
          if (t.banned) {
            for (const [key3, value3] of Object.entries(t.banned)) {
              if (
                key3.split("|")[0] == day &&
                value3 != "false" &&
                value3 == lesson
              ) {
                no = {
                  type: "teacher-banned",
                  text: "هذا المعلم محجوز له هذه الحصة",
                };
              }
            }
          }
          var table = g.subjectTable(t.subject_id);
          if (table) {
            for (const [key3, value3] of Object.entries(table)) {
              if (
                key3.split("|")[0] == day &&
                value3 != "false" &&
                value3 == lesson
              ) {
                no = {
                  type: "subject-banned",
                  text: "هذه المادة محجوزة هذه الحصة",
                };
              }
            }
          }
          if (no) {
            if (!g.errors[classroom]) {
              g.errors[classroom] = {};
            }
            if (!g.errors[classroom][day]) {
              g.errors[classroom][day] = {};
            }
            if (!g.errors[classroom][day][lesson]) {
              g.errors[classroom][day][lesson] = no;
              g.errorsCount[classroom] =
                (g.errorsCount[classroom] ? g.errorsCount[classroom] : 0) + 1;
              g.errorsCount["total"] =
                (g.errorsCount["total"] ? g.errorsCount["total"] : 0) + 1;
            }
          }
        }
      }
    },
    drag(day, classroom, lesson) {
      if ($("body").hasClass("move")) {
        $("body").removeClass("move");
        $("table").removeClass("move");
        $("td").removeClass("move");
        var l = this.selectedDrag,
          g = this.calendar[this.selectedDrag];
        this.calendar[this.selectedDrag] =
          this.calendar[day + "|" + classroom + "|" + lesson];
        this.calendar[day + "|" + classroom + "|" + lesson] = g;
        $("[fullid='" + day + "|" + classroom + "|" + lesson + "']").addClass(
          "bg-light-success"
        );
        $("[fullid='" + l + "']").addClass("bg-light-info");
        setTimeout(() => {
          $(
            "[fullid='" + day + "|" + classroom + "|" + lesson + "']"
          ).removeClass("bg-light-success");
          $("[fullid='" + l + "']").removeClass("bg-light-info");
          $("[fullid='" + l + "']").removeClass("bg-light-success");
        }, 3000);
        this.selectedDrag = {};
        this.checkErrors();
      } else {
        this.selectedDrag = day + "|" + classroom + "|" + lesson;
        $("[fullid='" + day + "|" + classroom + "|" + lesson + "']").addClass(
          "bg-light-success"
        );
        $("body").addClass("move");
        $("table").addClass("move");
        $("td").addClass("move");
      }
    },
    dragTo(day, classroom, lesson, day2, classroom2, lesson2) {
      var g = this.calendar[day2 + "|" + classroom2 + "|" + lesson2];
      this.calendar[day2 + "|" + classroom2 + "|" + lesson2] =
        this.calendar[day + "|" + classroom + "|" + lesson];
      this.calendar[day + "|" + classroom + "|" + lesson] = g;
    },
    solveErrors(l = false) {
      var g = this;
      for (const [classroom1, days] of Object.entries(this.errors)) {
        for (const [day1, lessons] of Object.entries(days)) {
          for (const [lesson1, error] of Object.entries(lessons)) {
            if (
              error.type == "teacher-banned" ||
              error.type == "subject-banned"
            ) {
              for (const [key, value] of Object.entries(g.calendar)) {
                var day = key.split("|")[0],
                  classroom = key.split("|")[1],
                  lesson = key.split("|")[2];
                if (
                  day == day1 &&
                  classroom == classroom1 &&
                  lesson1 != lesson
                ) {
                  //
                  var t = value,
                    no = false;
                  if (t.banned) {
                    for (const [key3, value3] of Object.entries(t.banned)) {
                      if (
                        key3.split("|")[0] == day &&
                        value3 != "false" &&
                        value3 == lesson
                      ) {
                        no = true;
                      }
                    }
                  }
                  for (const [key, value] of Object.entries(g.calendar)) {
                    var day2 = key.split("|")[0],
                      classroom2 = key.split("|")[1],
                      lesson2 = key.split("|")[2];
                    if (
                      day == day2 &&
                      lesson2 == lesson &&
                      value.teacher_id == t.teacher_id
                    ) {
                      no = true;
                    }
                  }
                  var table = g.subjectTable(
                    g.calendar[day1 + "|" + classroom1 + "|" + lesson1]
                      .subject_id
                  );
                  if (table) {
                    for (const [key3, value3] of Object.entries(table)) {
                      if (
                        key3.split("|")[0] == day &&
                        value3 != "false" &&
                        value3 == lesson
                      ) {
                        no = true;
                      }
                    }
                  }
                  if (!no) {
                    g.dragTo(day, classroom, lesson, day1, classroom1, lesson1);
                    break;
                  }
                  //
                }
              }
            }
          }
        }
      }
      g.checkErrors();
      if (l == true) {
        for (var i = 0; i <= 1; i++) {
          g.solveErrors();
        }
      }
    },
    saveTable() {
      var g = this;
      this.loading = true;
      $.post(api + "/table/save", {
        jwt: user.jwt,
        id: g.id,
        calendar: JSON.stringify(g.calendar),
        title: g.title,
      })
        .then(function (r) {
          g.loading = false;
          swal.fire("تم الحفظ", "", "success");
        })
        .catch(function () {
          swal.fire(
            "حدث خطأ اثناء الاتصال بالخادم",
            "برجاء المحاولة مرة أخرى",
            "warning"
          );
          g.loading = false;
        });
    },
    classroomName(id) {
      var n = null;
      this.classrooms.forEach(function (a) {
        if (a.id == id) {
          n = a.title;
        }
      });
      return n;
    },
  },
};
</script>

<style>
.drag {
  border: 2px solid #ddd !important;
  background: #fff;
}
.drag:hover {
  border-bottom: 2px solid red !important;
  background: #eee;
}
.move {
  cursor: move;
}
.move .drag:hover {
  border-bottom: 2px solid green !important;
  background: lightgreen;
}
</style>